import React from 'react';

import Layout from '../components/layout';


class IndexPage extends React.Component {

  render() {

    return (
      <Layout>
        <section className="section-home">

          {/*
          "We're obsessed with providing a terrific experience. If not, we'll make it right - guaranteed."
          */}

          <div id="root">
            <div className="ae af">


              <main id="main">
                <div className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                  <div className="bj bs bt">
                    <div className="ci bt bs lc ld le cm lf lg lh li lj lk ll lm ln lo lp lq">
                      <div className="mp mq mr ms mt">
                        <div className="al bi bj k9 o6">
                          <div className="an m1 m2 hq c4">
                            <div className="af bi">
                              <div className="bv af j3 bj">
                                <img src="images/D_Homepage_Header2x.webp" className="cq j4 af an j5 se bj"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mu mk mv mw mx my mz">
                        <h1 className="o7 o8 o9 oa ob oc gs dd gt gu fx gv gw">Move the way you want</h1></div>
                      <div className="n0 jt n1 my">
                        <div className="bv bw af bj">
                          <div className="pw px py">
                            <h2 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Drive</h2></div>
                          <div className="p2 pz q0">
                            <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Drive when you want. Find
                              opportunities around you.<br/><a className="cmln__link" href="https://web.bellme.xyz?appId=lcc">Learn more</a></p>
                            </div>
                          </div>
                          <div className="ql"><a href="https://web.bellme.xyz?appId=lcc" target="_self" aria-label="" className="ga gb gc gd ge gf gg gh gi gj">
                            <div className="gt gu gb rw al rx bu hg e8 ic du ry gd">Sign up to drive
                              <div className="s0 s1 s2 pe i7 i8">
                                <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                  <path fillRule="evenodd" clipRule="evenodd"
                                        d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                </svg>
                              </div>
                            </div>
                          </a></div>
                        </div>
                      </div>
                      <div className="n2 jt n1 my">
                        <div className="n0 jt n1 my">
                          <div className="bv bw af bj">
                            <div className="pw px py"><h2 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Ride</h2></div>
                            <div className="p2 pz q0">
                              <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Tap your phone. Get where
                                you're headed.<br/><a className="cmln__link" href="https://web.bellme.xyz?appId=ride">Learn more</a></p>
                              </div>
                            </div>
                            <div className="ql"><a href="https://web.bellme.xyz?appId=ride" target="_self" aria-label="" className="ga gb gc gd ge gf gg gh gi gj">
                              <div className="gt gu gb rw al s3 ri hg e8 ic du s4 gd r4">Sign up to ride
                                <div className="s0 s1 s2 pe i7 i8">
                                  <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                  </svg>
                                </div>
                              </div>
                            </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-banner-cta b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt"><a href="https://web.bellme.xyz?appId=ride"
                                               className="bi cq fe lr gb ae ls gs lt lu al ih if lv lw lx ly lz m0">
                    <div className="af bi">
                      <div className="bv af j3 bj">
                        <img src="images/map-D2x_v2.webp" className="cq j4 af an j5 se bj"/>
                      </div>
                    </div>
                    <div className="an m1 c4 bj af bv e8">
                      <div className="bv ng dz nh ae ni">
                        <div className="ae bv e8 nh od oe of bn og oh oi br">
                          <div className="ir hc nv nu"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Where to?</h2></div>
                          <div className="dt du fe hd pp">Get a price estimate</div>
                        </div>
                        <div className="ae oj bv e8 j7 ok ol i8 om on oo gz op oq">
                          <div className="pq pr ps j4 bh">
                            <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a></div>
                </div>


                <div className="wcb3-multilink-bold b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt">
                    <div className="ci ck cj jr lb">
                      <div className="el em fi ml kc mm lb mn"><a href="https://web.bellme.xyz?appId=lcc" target="_self" aria-label=""
                                                                  className="ga gb gc gd jx k1 jq jt ot n1">
                        <div className="pt pu bf gb gs bi bj af bv dz b7 pv jk">
                          <div className="ci qn bj qo">
                            <div className="el rg"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Sign up to drive</h2></div>
                            <div className="o3 rh">
                              <div className="rk al cq rl i8 rm rn">
                                <div className="rr rs bv rt pr ps ru rv dy">
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a></div>
                      <div className="fg fh fi ml mo kd lb mn"><a href="https://web.bellme.xyz?appId=ride" target="_self" aria-label=""
                                                                  className="ga gb gc gd jx k1 jq jt ot n1">
                        <div className="pt pu bf gb gs bi bj af bv dz b7 pv jk">
                          <div className="ci qn bj qo">
                            <div className="el rg"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Sign up to ride</h2></div>
                            <div className="o3 rh">
                              <div className="rk al cq rl i8 rm rn">
                                <div className="rr rs bv rt pr ps ru rv dy">
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a></div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-legal b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt">
                    <div className="hj">
                      <div className="nd ne nf">
                        <div className="fe b9 fw fx fy fz g0 g1 g2 pc pd g4 g5 pe g6 pf pg ph pi pj pk pl pm pn po">
                          <p className="cmln__paragraph">Certain requirements and features vary by country, region, and city.</p>
                          <p>We're obsessed with providing a terrific experience. If not, we'll make it right - guaranteed.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>


            </div>
          </div>


        </section>
      </Layout>
    );
  }
}

export default IndexPage;
